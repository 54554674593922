<template>
  <div>
    <header-slot />

    <commissions
      :module="$route.matched[0].meta.module"
      tab="3"
    />

  </div>
</template>

<script>
import Commissions from '@/views/commons/components/commissions/CommissionsComponent.vue'

export default {
  components: {
    Commissions,
  },
}
</script>

<style>
</style>
